import React from 'react'
import { VStack } from 'react-stacked'
import Card, { cardAspectRatio, maxCardWidth } from '../../components/Card'
import { Subtitle } from '../../components/Text'
import AspectRatioFit from '../../components/AspectRatioFit'

export interface CardPageProps {
  active?: boolean
  text?: string
  videoURL?: string
  imageURL?: string
  cardTitle?: string
}

const CardPage: React.FC<CardPageProps> = ({ active = true, text, videoURL, imageURL, cardTitle }) => {
  return (
    <VStack justifyContent='stretch' alignItems='stretch' maxWidth={maxCardWidth} paddingHorizontal={50} grow={1}>
      <VStack grow={1} shrink={0} height='25%' />
      <VStack grow={2} justifyContent='center' alignItems='center' height='50%'>
        <AspectRatioFit aspectRatio={cardAspectRatio}>
          <Card videoURL={videoURL} imageURL={imageURL} width='100%' title={cardTitle} />
        </AspectRatioFit>
      </VStack>
      <VStack grow={1} shrink={0} justifyContent='center' alignItems='center' height='25%'>
        <Subtitle center>
          {text}
        </Subtitle>
      </VStack>
    </VStack>
  )
}

export default CardPage
