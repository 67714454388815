import React from 'react'
import { HStack } from 'react-stacked'
import styled from 'styled-components'

const Step = styled.div`
  flex-grow: 1;
  height: 2px;
  background: #ccc;
`

const ProgressBar = styled.div`
  height: 2px;
  animation: progress 5s linear forwards;
  background: #fff;

  @keyframes progress {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
`

export interface PageIndicatorProps {
  step: number
  totalSteps: number
  onTimeout?: () => void
  maxWidth?: number
}

const PageIndicator: React.FC<PageIndicatorProps> = ({ step, totalSteps, onTimeout, maxWidth }) => {
  const StepElements = React.useMemo(() => {
    return [...Array(totalSteps)].map((_, i) => {
      return (
        <Step key={i}>
          {i === step && <ProgressBar onAnimationEnd={onTimeout} />}
        </Step>
      )
    }
    )
  }, [step, totalSteps, onTimeout])

  return (
    <HStack grow={1} maxWidth={maxWidth} gap={10}>
      {StepElements}
    </HStack>
  )
}

export default PageIndicator
