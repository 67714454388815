import React, { useEffect } from 'react'
import styled from 'styled-components'

const Outer = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`

const Inner = styled.div`
`

export interface AspectRatioFitProps {
  children?: React.ReactNode
  aspectRatio?: number
  style?: React.CSSProperties
}

const AspectRatioFit: React.FC<AspectRatioFitProps> = ({ children, aspectRatio = 1, style }) => {
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 })
  const ref = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleResize = (): void => {
      if (ref.current != null) {
        const { width, height } = ref.current.getBoundingClientRect()
        setDimensions({ width, height })
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return (): void => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const boundingAspect = dimensions.width / dimensions.height
  const setHeight = boundingAspect > aspectRatio

  return (
    <Outer ref={ref} style={style}>
      <Inner style={{
        width: setHeight ? 'auto' : '100%',
        height: setHeight ? '100%' : 'auto',
        aspectRatio
      }}
      >
        {children}
      </Inner>
    </Outer>
  )
}

export default AspectRatioFit
