import React from 'react'

export interface TextProps extends React.HTMLAttributes<HTMLParagraphElement> {
  muted?: boolean
  center?: boolean
}

const Text: React.FC<TextProps> = (props) => {
  return (
    <p
      {...props} style={{
        ...props.style,
        color: props.muted === true ? '#909090' : '#000',
        textAlign: props.center === true ? 'center' : 'left',
        margin: 0
      }}
    >
      {props.children}
    </p>
  )
}

export const Subtitle: React.FC<TextProps> = (props) => {
  return (
    <p
      {...props} style={{
        ...props.style,
        fontSize: 25,
        fontFamily: 'ABCROMCondensed-Regular',
        color: props.color ?? (props.muted === true ? '#909090' : '#fff'),
        textAlign: props.center === true ? 'center' : 'left',
        margin: 0
      }}
    >
      {props.children}
    </p>
  )
}

export const CardTitle: React.FC<TextProps> = (props) => {
  return (
    <p
      {...props} style={{
        ...props.style,
        fontSize: 38,
        fontFamily: 'ABCROMCondensed-Regular',
        color: props.color ?? (props.muted === true ? '#909090' : '#000'),
        textAlign: props.center === true ? 'center' : 'left',
        margin: 0
      }}
    >
      {props.children}
    </p>
  )
}

export default Text
