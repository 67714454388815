import React, { useRef } from 'react'
import ScrollSnap, { ScrollSnapRef } from '../components/ScrollSnap'
import styled from 'styled-components'
import Navbar from '../components/Navbar'
import Intro from './landing/Intro'
import AnimatedBlurBackground from '../components/AnimatedBlurBackground'
import { ColorBlobData } from '../components/AnimatedBlurBackground/ColorBlob'
import CardPage from './landing/CardPage'
import videos from '../lib/videos'
import images from '../lib/images'

const AbsoluteContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`

const blobConfig: ColorBlobData[] = [
  {
    color: '#B5B5B5',
    size: 0.45,
    x: 0.9,
    y: -0.05,
    blur: 160
  },
  {
    color: '#EAAFFF',
    size: 0.45,
    x: 0.6,
    y: 0.6,
    blur: 200
  },
  {
    color: '#FB8451',
    size: 0.4,
    x: 0.4,
    y: 0.5,
    blur: 200
  },
  {
    color: '#FF5252',
    size: 0.45,
    x: 0.7,
    y: 0.65,
    blur: 200
  },
  {
    color: '#FF6B00',
    size: 0.3,
    x: 1,
    y: 0.1,
    blur: 200
  }
]

const backgroundColors = [
  '#fff',
  '#B19222',
  '#C5C5C5',
  '#4EA3AF',
  '#A090AD'
]

const Landing: React.FC = () => {
  const [page, setPage] = React.useState(0)
  const scrollRef = useRef<ScrollSnapRef>(null)

  const scrollTo = (page: number): void => {
    scrollRef.current?.scrollTo(page)
  }

  return (
    <AnimatedBlurBackground
      backgroundColor={backgroundColors[page % backgroundColors.length]}
      colorBlobs={
      [
        blobConfig[page % blobConfig.length]
      ]
    }
    >
      <AbsoluteContainer>
        <Navbar
          logoColor={page === 0 ? '#B5B5B5' : '#fff'}
          step={page - 1}
          totalSteps={backgroundColors.length - 1}
          showSteps={page > 0}
          onNext={() => scrollTo(page + 1)}
        />
      </AbsoluteContainer>

      <ScrollSnap onPageChange={setPage} ref={scrollRef}>
        <Intro />
        <CardPage videoURL={videos.sitting} text='TOGETHER is your new platform for Guided Conversations.' />
        <CardPage videoURL={videos.coffee} text='Discover new perspectives TOGETHER and create deep connections.' />
        <CardPage imageURL={images.sessionThumb1} cardTitle='Tipping the scale' text='TOGETHER is a collection of sessions with the purpose of guiding you forward.' />
        <CardPage videoURL={videos.appOpen} text='TOGETHER is available for download in beta version for iPhone.' />
      </ScrollSnap>
    </AnimatedBlurBackground>
  )
}

export default Landing
