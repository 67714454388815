import React from 'react'
import Card, { cardAspectRatio, maxIntroCardWidth } from '../../components/Card'
import styled from 'styled-components'
import { VStack } from 'react-stacked'
import { Subtitle } from '../../components/Text'
import AspectRatioFit from '../../components/AspectRatioFit'
import videos from '../../lib/videos'

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0px;
  max-width: ${maxIntroCardWidth * 3}px;
  width: 100%;
`

const Desktop = styled.div`
  @media (max-width: 1000px) {
    display: none;
  }
`

const Mobile = styled.div`
  display: none;
  @media (max-width: 1000px) {
    display: block;
  }
`

const threeByTwoAspect = (3 * cardAspectRatio) / (2)

const Intro: React.FC = () => {
  return (
    <VStack grow={1} padding={35} width='100%' justifyContent='stretch' alignItems='stretch' gap={50}>
      <VStack height='25%'>
        <Desktop>
          <Subtitle color='#9355C3' center>Discover TOGETHER. Your new platform for Guided Conversations.</Subtitle>
        </Desktop>
      </VStack>
      <VStack height='50%' alignItems='center'>
        <AspectRatioFit aspectRatio={threeByTwoAspect} style={{ maxWidth: maxIntroCardWidth * 3 }}>
          <Grid style={{ aspectRatio: threeByTwoAspect }}>
            <Card width='100%' videoURL={videos.girl} />
            <Card width='100%' backgroundColor='transparent' />
            <Card width='100%' videoURL={videos.guyFlipped} />

            <Card width='100%' backgroundColor='transparent' />
            <Card width='100%' videoURL={videos.appCards} />
            <Card width='100%' backgroundColor='transparent' />
          </Grid>
        </AspectRatioFit>
      </VStack>
      <VStack height='25%'>
        <Mobile>
          <Subtitle color='#9355C3' center>Discover TOGETHER. Your new platform for Guided Conversations.</Subtitle>
        </Mobile>
      </VStack>
    </VStack>
  )
}

export default Intro
