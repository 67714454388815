import React, { useEffect, useImperativeHandle, useRef } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import useWindowDimensions from '../hooks/useWindowDimensions'

const GlobalStyle = createGlobalStyle`
  html {
    overflow: hidden;
    overscroll-behavior: none;
  }

  body {
    touch-action: pan-y;
  }
`

const Container = styled.div<{ height: number }>`
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: ${props => props.height}px;
`

const Page = styled.section<{ height: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${props => props.height}px;

  scroll-snap-align: start;
`

export interface ScrollSnapProps {
  children?: React.ReactNode
  onPageChange?: (page: number) => void
}

export interface ScrollSnapRef {
  scrollTo: (page: number) => void
}

const ScrollSnap: React.ForwardRefRenderFunction<ScrollSnapRef, ScrollSnapProps> = (
  { children, onPageChange },
  ref
) => {
  const viewPortHeight = useWindowDimensions().height
  const [currentPage, setCurrentPage] = React.useState(0)
  const containerRef = useRef<HTMLDivElement>(null)

  useImperativeHandle(
    ref,
    () => ({
      scrollTo: (page) => {
        if (containerRef.current != null) {
          containerRef.current.scrollTo({
            top: page * viewPortHeight,
            behavior: 'smooth'
          })
        }
      }
    }),
    [viewPortHeight]
  )

  // wrap each child in Page
  const elements = React.Children.map(children, (child) => {
    return <Page height={viewPortHeight}>{child}</Page>
  })

  useEffect(() => {
    onPageChange?.(currentPage)
  }, [currentPage, onPageChange])

  return (
    <Container
      ref={containerRef}
      height={viewPortHeight}
      style={{
        scrollSnapType: 'y mandatory'
      }}
      onScroll={(e) => {
        const target = e.target as HTMLElement
        const scrollY = target.scrollTop
        const page = Math.round(scrollY / viewPortHeight)
        if (page !== currentPage) {
          setCurrentPage(page)
        }
      }}
    >
      <GlobalStyle />
      {elements}
    </Container>
  )
}

export default React.forwardRef(ScrollSnap)
