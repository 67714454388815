import React, { useEffect } from 'react'
import styled from 'styled-components'
import ColorSplash from './ColorSplash'
import { CardTitle } from './Text'

const Measure = styled.div`
  position: relative;
`

const Abs = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

export interface GlowTextProps {
  children: React.ReactNode
  color?: string
  blur?: number
  scale?: number
}

const GlowText: React.FC<GlowTextProps> = ({ children, color = '#FFD600', blur = 50, scale = 1 }) => {
  const [dimesions, setDimensions] = React.useState({ width: 0, height: 0 })
  const ref = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    const updateDimensions = (): void => {
      if (ref.current != null) {
        setDimensions({
          width: ref.current.clientWidth,
          height: ref.current.clientHeight
        })
      }
    }
    updateDimensions()
    window.addEventListener('resize', updateDimensions)
  }, [])

  console.log(dimesions)

  return (
    <Measure ref={ref}>
      <ColorSplash
        color={color}
        opacity={1}
        width={dimesions.width * scale}
        height={dimesions.height * scale}
        blur={blur}
        x={dimesions.width * scale / 4}
        y={dimesions.height * scale / 4}
      />
      <CardTitle center style={{ opacity: 0, padding: 40 }}>
        {children}
      </CardTitle>
      <Abs>
        <CardTitle center style={{ padding: 40 }}>
          {children}
        </CardTitle>
      </Abs>
    </Measure>
  )
}

export default GlowText
