import React from 'react'
import Landing from './pages/Landing'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Landing />} />
      </Routes>
    </Router>
  )
}

export default App
