import styled from 'styled-components'

// Radial gradients can be used but dont look like blurred shapes like shadows do
// import { Defs, Ellipse, RadialGradient, Stop, Svg } from 'react-native-svg'

const Outer = styled.div<{ opacity: number }>`
  width: 1px;
  height: 1px;
  overflow: visible;
  position: absolute;
  opacity: ${(props) => props.opacity};
  top: 50%;
  left: 50%;
`

const Blur = styled.div<{ blur: number, width: number, heigth: number, color: string, x: number, y: number }>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.heigth}px;
  background-color: #fff;
  border-radius: ${(props) => props.width / 2}px;

  box-shadow: ${(props) => props.x + 10000 - props.width / 2}px ${(props) => props.y - props.heigth / 2}px ${(props) => props.blur}px ${(props) => props.color};
  transform: translateX(${(props) => -10000 - props.width / 2 + props.x}px) translateY(${(props) => -props.heigth / 2 + props.y}px);
  left: 0;
  top: 0;
`

export interface ColorSplashProps {
  color: string
  opacity?: number
  width?: number
  height?: number
  x?: number
  y?: number
  animated?: boolean // If true it will animate the color change
  style?: React.CSSProperties
  blur?: number
}

const ColorSplash: React.FC<ColorSplashProps> = ({ color, opacity = 0.7, width = 100, height = 100, x = 0, y = 0, style, blur = 20 }) => {
  return (
    <Outer opacity={opacity} style={style}>
      <Blur blur={blur} width={width} heigth={height} color={color} x={x} y={y} />
    </Outer>
  )
}

export default ColorSplash
