const links = {
  get coffee () { return 'https://firebasestorage.googleapis.com/v0/b/presence-4a3f8.appspot.com/o/videos%2FcrIKiOKEyFnLmZDa0MiH%2Fsd.mp4?alt=media&token=75e2fb52-f6ab-4bf2-af46-41cccb8a9a83' },
  get girl () { return 'https://firebasestorage.googleapis.com/v0/b/presence-4a3f8.appspot.com/o/videos%2FLtvUqZjqCXZkqE2czcx3%2Fsd.mp4?alt=media&token=ae620208-f254-44d3-9eeb-cae55cdc13b3' },
  get guy () { return 'https://firebasestorage.googleapis.com/v0/b/presence-4a3f8.appspot.com/o/videos%2FuI6Mr9o9fHFvzEKV1Pga%2Fsd.mp4?alt=media&token=2e589117-7493-4e07-ac7b-23f1edbaaf09' },
  get guyFlipped () { return 'https://firebasestorage.googleapis.com/v0/b/presence-4a3f8.appspot.com/o/videos%2Fs2CYsd1F7khPmwzV8s99%2Fsd.mp4?alt=media&token=2ed30875-9b95-472d-b1ec-78c4f3ce7a94' },
  get sitting () { return 'https://firebasestorage.googleapis.com/v0/b/presence-4a3f8.appspot.com/o/videos%2FlF1hPTQiahoixbkQIYci%2Fsd.mp4?alt=media&token=bc37ebc3-f85e-40a8-89d7-c7ee5dccb4f7' },
  get appOpen () { return 'https://firebasestorage.googleapis.com/v0/b/presence-4a3f8.appspot.com/o/videos%2FvGtuYNfuL7VtgGvRS5qb%2Fsd.mp4?alt=media&token=14a19cac-1459-4113-bca7-e7d7de938d48' },
  get appCards () { return 'https://firebasestorage.googleapis.com/v0/b/presence-4a3f8.appspot.com/o/videos%2FDWhAgVT0jKkqJ9EhcNzk%2Fsd.mp4?alt=media&token=b318f5af-655b-44dc-bfcf-13c0b9e7b8ab' }
}

export default links
