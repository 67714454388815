import React from 'react'

export interface LogoProps {
  style?: React.CSSProperties
}

const Logo: React.FC<LogoProps> = ({ style }) => {
  return (
    <svg
      style={style}
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd'
      strokeLinejoin='round'
      strokeMiterlimit='2'
      clipRule='evenodd'
      viewBox='0 0 360 338'
    >
      <path d='M120 13.658C120 6.12 113.88 0 106.342 0H13.658C6.12 0 0 6.12 0 13.658v141.434c0 7.538 6.12 13.658 13.658 13.658h92.684c7.538 0 13.658-6.12 13.658-13.658V13.658zM360 13.658C360 6.12 353.88 0 346.342 0h-92.684C246.12 0 240 6.12 240 13.658v141.434c0 7.538 6.12 13.658 13.658 13.658h92.684c7.538 0 13.658-6.12 13.658-13.658V13.658zM240 182.408c0-7.538-6.12-13.658-13.658-13.658h-92.684c-7.538 0-13.658 6.12-13.658 13.658v141.434c0 7.538 6.12 13.658 13.658 13.658h92.684c7.538 0 13.658-6.12 13.658-13.658V182.408z' />
    </svg>
  )
}

export default Logo
