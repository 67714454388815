import React from 'react'
import { HStack, VStack } from 'react-stacked'
import styled from 'styled-components'
import Logo from '../img/Logo'
import PageIndicator from './PageIndicator'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { maxCardWidth } from './Card'

const Outline = styled.a`
  border: 1px solid #fff;
  border-radius: 12px;
  color: #fff;
  padding: 7px 20px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'ABCROMMono-Regular';
  text-transform: uppercase;
  font-size: 16px;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
`

interface NavbarProps {
  logoColor: string
  step: number
  totalSteps: number
  showSteps?: boolean
  onNext?: () => void
}

const Navbar: React.FC<NavbarProps> = ({ logoColor, step, totalSteps, showSteps = true, onNext }) => {
  const { width } = useWindowDimensions()
  const isMobile = width < 600
  return (
    <VStack>
      <HStack width='100%' padding={30}>
        <HStack width={120}>
          <Logo style={{ width: 40, height: 40, fill: logoColor }} />
        </HStack>
        <HStack grow={1} justifyContent='center' alignItems='center'>
          {!isMobile && showSteps && <PageIndicator step={step} totalSteps={totalSteps} maxWidth={maxCardWidth} onTimeout={onNext} />}
        </HStack>
        <HStack width={120} justifyContent='end'>
          <Outline href='https://testflight.apple.com/join/3TZsiGt3' target='_blank'>
            Download
          </Outline>
        </HStack>
      </HStack>
      {isMobile && (
        <HStack grow={1} paddingHorizontal={20} justifyContent='center'>
          {showSteps && <PageIndicator step={step} totalSteps={totalSteps} onTimeout={onNext} />}
        </HStack>
      )}
    </VStack>
  )
}

export default Navbar
