import { animated, useSpring } from '@react-spring/web'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import GlowText from './GlowText'

export const cardAspectRatio = 260 / 360
export const maxIntroCardWidth = 260
export const maxCardWidth = 427

const borderRadius = `10%/${cardAspectRatio * 10}%`

const Container = styled(animated.div)`
  border-radius: ${borderRadius};
  aspect-ratio: ${cardAspectRatio};
`

// Fix webkit scroll video bug https://bugs.webkit.org/show_bug.cgi?id=261563
const Overlay = styled.div`
  position: absolute;
  border-radius: ${borderRadius};
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

export interface CardProps {
  children?: React.ReactNode
  backgroundColor?: string
  imageURL?: string
  videoURL?: string
  flipOnLoad?: boolean
  width?: React.CSSProperties['width']
  maxWidth?: React.CSSProperties['maxWidth']
  title?: string
}

const Card: React.FC<CardProps> = ({ children, backgroundColor = '#fff', imageURL, videoURL, flipOnLoad = true, width, maxWidth, title }) => {
  const [loaded, setLoaded] = React.useState(!flipOnLoad)
  const flipStyle = useSpring({
    transform: loaded ? 'rotateY(0deg)' : 'rotateY(90deg)'
  })

  useEffect(() => {
    if (videoURL == null && imageURL == null) {
      window.setTimeout(() => {
        setLoaded(true)
      }, 1000)
    }
  }, [videoURL, imageURL])

  return (
    <Container
      style={{
        ...flipStyle,
        backgroundColor,
        width,
        maxWidth
      }}
    >

      {videoURL != null && (
        <video
          src={videoURL}
          autoPlay
          loop
          muted
          controls={false}
          playsInline
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius,
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1
          }}
          onLoadedData={() => setLoaded(true)}
        />
      )}

      {imageURL != null && (
        <img
          src={imageURL}
          alt='session-thumbnail'
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius,
            position: 'absolute',
            top: 0,
            left: 0
          }}
          onLoad={() => setLoaded(true)}
        />
      )}

      <Overlay>
        {title != null && <GlowText>{title}</GlowText>}
      </Overlay>

      {children}
    </Container>
  )
}

export default Card
