import React from 'react'
import styled from 'styled-components'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { animated, useSpring } from '@react-spring/web'

const Circle = styled(animated.div)`
  border-radius: 50%;
  background-color: #B5B5B5;
  position: absolute;
  z-index: 0;
`

export interface ColorBlobData {
  color: string
  size: number // 0-1 of viewport width
  x: number // 0-1 of viewport width
  y: number // 0-1 of viewport height
  blur: number
}

export interface ColorBlobProps {
  value: ColorBlobData
}

const ColorBlob: React.FC<ColorBlobProps> = ({ value }) => {
  const { height: viewPortHeight, width: viewPortWidth } = useWindowDimensions()
  const size = value.size * viewPortWidth

  const animatedStyle = useSpring({
    backgroundColor: value.color,
    width: size,
    height: size,
    // filter: `blur(${value.blur}px)`,
    // Preferebly we would use filter: blur() but it has some wierd render issues on Safari instead we use box-shadow hack showing only the shadow
    transform: 'translateX(-10000px)',
    boxShadow: `10000px 0px ${value.blur}px ${value.blur}px ${value.color}`,

    left: `${value.x * viewPortWidth - size / 2}px`,
    top: `${value.y * viewPortHeight - size / 2}px`
  })

  return (
    <Circle
      style={animatedStyle}
    />
  )
}

export default ColorBlob
