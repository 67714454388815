import React from 'react'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import ColorBlob, { ColorBlobData } from './ColorBlob'
import { animated, useSpring } from '@react-spring/web'
import styled from 'styled-components'

const Container = styled(animated.div)<{ height: number }>`
  overflow: visible;
  width: 100vw;
  position: relative;
  height: ${props => props.height}px;
`

export interface AnimatedBlurBackgroundProps {
  backgroundColor?: string
  children?: React.ReactNode
  colorBlobs: ColorBlobData[]
}

const AnimatedBlurBackground: React.FC<AnimatedBlurBackgroundProps> = ({ backgroundColor = '#fff', children, colorBlobs }) => {
  const { height: viewPortHeight } = useWindowDimensions()
  const animatedStyle = useSpring({
    backgroundColor
  })

  return (
    <Container height={viewPortHeight} style={animatedStyle}>

      {colorBlobs.map((blob, index) => <ColorBlob key={index} value={blob} />)}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100vw',
          height: viewPortHeight
        }}
      >
        {children}
      </div>
    </Container>
  )
}

export default AnimatedBlurBackground
